<template>
  <div>
    <div class="product-list-box" v-if="myArray.length">
      <div class="product-list-box-top">
        <span class="t1">产品名称</span>
        <span class="t2">置顶</span>
        <span class="t3">拖动</span>
      </div>
      <div class="product-list-box-cent">
        <draggable
          v-model="myArray"
          chosenClass="chosen"
          forceFallback="true"
          group="people"
          animation="1000"
          handle=".t3"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group>
            <div class="product-list-box-cent-item" v-for="(item, index) in myArray" :key="item.game_id">
              <span class="t0" @click="fnDel(index)">
                <em></em>
              </span>
              <span class="t1">
                <h2>{{ item.game_name }}</h2>
                <p>{{ game_type_name[item.game_type] }}</p>
              </span>
              <span class="t2" @click="fnZhiDing(index)"><i></i></span>
              <span class="t3"><i></i></span>
            </div>
          </transition-group>
        </draggable>
      </div>
      <router-link :to="{ path: '/search' }">
        <div class="product-list-add">新增关注<em></em></div>
      </router-link>
    </div>
    <div class="product-button">
      <span @click="fnSaveData">完成</span>
    </div>
    <van-overlay :show="showLoading">
      <div class="vant-loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { gameFocusList, gameFocusOrder } from '@/api/follow'
export default {
  //注册draggable组件
  components: {
    draggable
  },
  data() {
    return {
      myArray: [],
      game_type_name: {
        1: '网页游戏',
        2: '国内游戏',
        5: '海外游戏'
      },
      showLoading: false
    }
  },
  created() {
    this.fnInit()
  },
  methods: {
    fnInit() {
      this.fnFocuslist()
    },
    /**
     * 关注列表
     */
    fnFocuslist() {
      gameFocusList().then((data) => {
        console.log(data)
        const res = data.res
        this.myArray = res.list
      })
    },
    /**
     * 保存
     */
    fnSaveData() {
      let game_id = []
      this.myArray.map((item) => {
        game_id.push(item.game_id)
      })
      const params = {
        game_id: JSON.stringify(game_id)
      }
      this.showLoading = true
      gameFocusOrder(params).then((data) => {
        const error = data.error
        if (error === 1000) {
          console.log('保存成功', data)
          this.$toast('保存成功')
          this.showLoading = false
          setTimeout(() => {
            location.href = '/'
          }, 500)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 置顶
     */
    fnZhiDing(index) {
      console.log(index, this.myArray)
      const d = this.myArray.splice(index, 1)
      console.log(d)
      this.myArray.unshift(d[0])
    },
    /**
     * 删除
     */
    fnDel(index) {
      this.myArray.splice(index, 1)
    },
    /**
     * 开始拖拽事件
     */
    onStart() {
      this.drag = true
    },
    /**
     * 拖拽结束事件
     */
    onEnd() {
      this.drag = false
    }
  }
}
</script>

<style lang="less" scoped>
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

.product-list-box {
  padding-top: (8 / @num);
  padding-bottom: (168 / @num);
  .product-list-box-top,
  .product-list-box-cent-item {
    height: (80 / @num);
    font-size: @font-24;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    border-bottom:(2/@num) solid @font-F7F7F7;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: @font-a3a3a3;
      &.t0 {
        padding: 0 (20 / @num) 0 (32 / @num);
        em {
          display: block;
          width: (24 / @num);
          height: (24 / @num);
          background: url('../../assets/shape@2x.png') no-repeat;
          background-size: 100%;
        }
      }
      &.t1 {
        flex: 1;
        justify-content: flex-start;
        padding-left: (32 / @num);
        &.a {
          padding-left: 0;
        }
      }
      &.t2 {
        width: (180 / @num);
      }
      &.t3 {
        width: (140 / @num);
      }
      &.t4 {
        width: (140 / @num);
      }
    }
  }
  .product-list-box-top {
    margin-bottom: (2 / @num);
  }
  .product-list-box-cent {
    overflow: hidden;
    background: #fff;
    .product-list-box-cent-item {
      padding: (20 / @num) 0;
      color: @font-2f2f2f;
      height: auto;
      span {
        &.t1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: (10 / @num);
          h2 {
            color: @font-2f2f2f;
            font-size: @font-30;
            font-weight: normal;
          }
          p {
            margin-top: (12 / @num);
            color: @paramcolor;
            padding: 0 (20 / @num);
            height: (40 / @num);
            line-height: (40 / @num);
            text-align: center;
            background: linear-gradient(180deg, #e5f0ff 0%, #f7faff 100%);
            border-radius: (8 / @num);
            b {
              font-weight: normal;
            }
          }
        }
        &.t2 {
          i {
            display: block;
            width: (36 / @num);
            height: (40 / @num);
            background: url('../../assets/zhiding@2x.png') no-repeat;
            background-size: 100%;
          }
        }
        &.t3 {
          i {
            display: block;
            width: (50 / @num);
            height: (32 / @num);
            background: url('../../assets/tuodong@2x.png') no-repeat;
            background-size: 100%;
          }
        }
        &.t2,
        &.t3,
        &.t4 {
          color: @font-2f2f2f;
          &.r {
            color: @font-59a252;
          }
          &.h {
            color: @font-ea5656;
          }
        }
      }
    }
  }
}
.product-list-add {
  display: flex;
  justify-content: center;
  color: @paramcolor;
  height: (72 / @num);
  background: #fff;
  font-size: @font-28;
  align-items: center;
  margin-top: (4 / @num);
  em {
    display: inline-block;
    width: (24 / @num);
    height: (24 / @num);
    background: url('../../assets/jiahao@2x.png');
    background-size: 100%;
    margin-left: (10 / @num);
  }
}
.product-button {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  span {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: (654 / @num);
    background: @paramcolor;
    border-radius: (42 / @num);
    height: (80 / @num);
    font-size: @font-32;
    color: #fff;
    margin-top: (20 / @num);
    margin-bottom: (48 / @num);
  }
}
</style>